<template>
  <div>
    <div class="container">
      <div class="card px-5">
        <h1 class="my-4">Background Generator</h1>

        <div class="row">
          <div class="col-8">
            <div id="background-preview" ref="backgroundPreview" :style="backgroundPreviewStyle">
              <div v-for="(el, i) in shapeElements" :key="i" :style="[el.style, glassStyle]"></div>
            </div>
          </div>

          <div class="col-4">
            <button class="btn btn-primary mb-4" @click="regenerateCounter++">Regenerate</button>

            <div>
              <label>Width {{ options.width }}px</label>
              <input type="range" min="1" max="4096" step="1" v-model.number.lazy="options.width" class="form-control" />
            </div>
            <div>
              <label>Height {{ options.height }}px</label>
              <input type="range" min="1" max="4096" step="1" v-model.number.lazy="options.height" class="form-control" />
            </div>
            <div>
              <label># Elements {{ options.numElements }}</label>
              <input type="range" min="0" max="20" step="1" v-model.number.lazy="options.numElements" class="form-control" />
            </div>
            <div>
              <label>Base Color {{ options.baseColor }}</label>
              <input type="color" v-model="options.baseColor" class="form-control" />
            </div>
            <div>
              <label>Border Radius {{ options.borderRadius }}px</label>
              <input type="range" min="0" max="50" step="1" v-model.number.lazy="options.borderRadius" class="form-control" />
            </div>
            <div>
              <div v-for="shapeName in Object.keys(shapes)" :key="shapeName" class="form-check">
                <label class="form-check-label">{{ shapeName }}</label>
                <input class="form-check-input" type="checkbox" v-model="options.shapesEnabled[shapeName]">
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { hexToHSL } from '@util/color-conversions'

export default {
  components: {
  },
  data() {
    return {
      regenerateCounter: 0,
      options: {
        width: 512,
        height: 512,
        baseColor: '#585dfe',
        numElements: 10,
        borderRadius: 10,
        shapesEnabled: {
          trapezoid: false,
          triangle: false,
          rhombus: false,
          pentagon: false,
          frame: false,
          rectangle: true,
        },
      },
      shapes: {
        trapezoid: 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
        triangle: 'polygon(50% 0%, 0% 100%, 100% 100%)',
        rhombus: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
        pentagon: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
        frame: 'polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%)',
        rectangle: 'none',
      },
    }
  },
  watch: {
    'options.width'(newValue, oldValue) {
      console.log('watch options', newValue)
      this.generateBackground()
    }
  },
  computed: {
    backgroundPreviewStyle() {
      const i = this.regenerateCounter

      const styles = {}

      styles.width = this.options.width + 'px'
      styles.height = this.options.height + 'px'

      const hsl = hexToHSL(this.options.baseColor)
      const stop1 = 'hsl(' +
        hsl.h + 'deg,' +
        (hsl.s - 10) + '%,' +
        (hsl.l - 10) + '%' +
        ')'
      const stop2 = 'hsl(' +
        hsl.h + 'deg,' +
        (hsl.s + 0) + '%,' +
        (hsl.l + 0) + '%' +
        ')'
      const stop3 = 'hsl(' +
        hsl.h + 'deg,' +
        (hsl.s + 10) + '%,' +
        (hsl.l + 10) + '%' +
        ')'

      styles.background = 'linear-gradient(320deg,' + stop1 + ',' + stop2 + ',' + stop3 + ')'

      styles['clip-path'] = 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'

      return styles
    },
    glassStyle() {
      const i = this.regenerateCounter

      const styles = {
        background: 'rgba(255, 255, 255, 0.2)',
        'border-radius': this.options.borderRadius + 'px',
        'box-shadow': '0 4px 30px rgba(0, 0, 0, 0.1)',
        'backdrop-filter': 'blur(5px)',
        '-webkit-backdrop-filter': 'blur(5px)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
      }

      return styles

    },
    shapeElements() {
      const i = this.regenerateCounter

      const shapes = []
      Object.keys(this.options.shapesEnabled)
        .filter(k => this.options.shapesEnabled[k])
        .forEach((k) => {
          shapes.push(this.shapes[k])
        })

      const elements = []

      for (let i = 0; i < this.options.numElements; i++) {
        const width = this.getRandomInt(
          Math.round(this.options.width / 8),
          Math.round(this.options.width * 2)
        )
        const height = this.getRandomInt(
          Math.round(this.options.height / 8),
          Math.round(this.options.height * 2)
        )

        const left = this.getRandomInt(-width, this.options.width)
        const top = this.getRandomInt(-height, this.options.height)

        const opacity = this.getRandom(0.1, 0.5)
        //const transform = 'rotate(' + this.getRandom(0, 1) + 'turn)'
        const transform = 'rotate(0turn)'
        const shape = shapes[this.getRandomInt(0, shapes.length)]

        elements.push({
          style: {
            position: 'absolute',
            width: width + 'px',
            height: height + 'px',
            top: top + 'px',
            left: left + 'px',
            opacity,
            transform,
            'clip-path': shape,
            'z-index': 1,
          }
        })

      }

      console.log({elements})

      return elements
    }
  },
  mounted() {
    this.generateBackground()
  },
  methods: {
    getRandom(min, max) {
      return Math.random() * (max - min) + min
    },
    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min) + min)
    },
    generateBackground() {
      const el = this.$refs.backgroundPreview
      console.log({el})

    },
  }
}

</script>

<style lang="scss">

#background-preview {
  border: 1px solid rgba(0, 0, 0, 0.12);
}


</style>
